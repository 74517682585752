import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "../styles/about.css"

class AboutPage extends React.Component {
  

  render() {

    const {data} = this.props
    
    return ( 
      <>
      <SEO title="Om MB Racing" />
      <section id="about">
          <h2 className="heading">Om MB Racing</h2>
          <Img fluid={data.directusAbout.top_image.localFile.childImageSharp.fluid} className="opaque50 about--image-top" />
          <p className="about--catch">{data.directusAbout.catch}</p>
          <div className="opaque80 about--text" dangerouslySetInnerHTML={{__html: data.directusAbout.text}}></div>
          <Img fluid={data.directusAbout.additional_image.localFile.childImageSharp.fluid} className="opaque50 about--image-add" />
      </section>
    </>
  )}

}

export default AboutPage

export const query = graphql`
  query {
    directusAbout {
      catch
      text
      top_image {
        localFile {
          childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp            }
          }
        }
      }
      additional_image {
        localFile {
          childImageSharp {
            fluid(quality: 75, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp            }
          }
        }
      }
    }
  }
`